* {
  box-sizing: border-box;
  transition: all 0.6s linear;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/imgs/bg.webp');
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-size: 16px;
  max-width: 100vw;
}

body::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  background: rgb(248,251,246);
  background: linear-gradient(180deg, rgba(248,251,246,0.11) 0%, rgba(190,220,170,1) 74%, rgba(190,220,170,1) 83%, rgba(212,232,198,1) 100%);
}

.app {
  padding: 25px 30px;
}

.app > svg {
  position: absolute;
  z-index: -1;
}

.app > #elem-1 {
  width: 524px;
  height: 203px;
  top: -1px;
  right: -1px;
  transform-origin: 100% 0%;
}

.app > #elem-2 {
  width: 907px;
  height: 686px;
  top: -1px;
  left: -1px;
  transform-origin: 0% 0%;
  transform: scale(0.9)
}

.app > #elem-3 {
  width: 438px;
  height: 337px; 
  top: 300px;
  right: 0px;
  transform-origin: 100% 100%;
  transform: scale(0.5)
}

.inicio {
  min-height: 550px;
}

.inicio > div > h1 {
  color: #FFF;
  text-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  font-size: 350%;
  padding: 10px 20px;
}

.inicio > div > h1 > span {
  display: block;
}

.inicio > div > h1 > span.upper {
  font-size: 125%;
}

.inicio > div > h1 > span.year {
  font-size: 150%;
  text-align: center;
  font-weight: 300;
}

.inicio > div > h1 > span > sub {
  font-weight: 400;
  padding: 0px 0px 0px 10px;
  position: relative;
  bottom: -10px;
}

.inicio .date {
  max-width: 350px;
}

.date > div > div {
  column-gap: 20px;
}

.date svg {
  width: 50px;
  height: auto;
  fill: #FFF;
  text-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.date h2, .date h3 {
  color: #FFF;
  text-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.programa > div {
  background: #FFF;
  box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  padding: 20px;
  z-index: -2;
  position: relative;
  margin-bottom: 20px;
}

.programa > div > div {
  max-width: 1280px;
  margin: 0 auto;
}

.programa table {
  border: 0;
}

.programa .tOrg tr > td:first-of-type > p {
  text-align: right !important;
  padding-right: 10px;
  text-wrap: nowrap;
}

.programa .tOrg p {
  text-align: left;
}

.programa .tOrg p > i {
  font-weight: 300;
  font-style: normal;
}

.programa .p-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.programa .p-list .col {
  width: 560px;
}

.programa .p-list .col > div {
  border-bottom: 1px dashed rgb(190,220,170);
  padding-bottom: 10px;
}

.programa .p-list .col > div:last-of-type {
  border-bottom: none;
}

.programa .p-list .col > .h1 {
  min-height: 305px;
}

.programa .p-list .col > .h2 {
  min-height: 430px;
}

.programa .p-list .col > .h2_1 {
  min-height: 270px;
}

.programa .p-list .col > .h2_2 {
  min-height: 160px;
}

.programa .p-list .col > .h3 {
  min-height: 370px;
}

.programa .p-list .col > .h3_1 {
  min-height: 80;
}

.programa .p-list .col > .h3_2 {
  min-height: 290px;
}

.programa .p-list .col table {
  width: 100%;
}

.programa .p-list .col table td:first-of-type {
 width: 300px; 
 padding-right: 10px;
}

.programa .p-list .col table p {
  margin: 3px 0px;
}

.programa .p-list .col .col-row-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

.programa .p-list h3 {
  color: #548235;
  font-weight: 700;
}

.programa .p-list .col-row-title  ~ p {
  padding-left: 20px;
}

.programa .p-list > div svg {
  width: 30px;
  height: auto;
}

.programa .p-list ul > li {
  margin-bottom: 10px;
}

#inscricao {
  position: relative;
  z-index: 0;
}

#inscricao > h1 {
  text-align: center;
  margin: 30px 0px;
}

#inscricao > .posters,
#inscricao > form {
    background: #FFF;
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.2);
    border-radius: 5px;
    padding: 20px;
    z-index: -2;
    position: relative;
    margin-bottom: 20px;
}

#inscricao > .posters > div,
#inscricao > form > div {
  max-width: 1280px;
  margin: 0 auto;
}

#inscricao > .posters h2 {
  color: rgb(255, 153, 0);
}

#inscricao > .posters p {
  font-size: 18px;
}

#inscricao > .posters p a {
  color: rgb(255, 153, 0);
}

#inscricao > .posters p u {
  color: #548235;
  font-weight: 700;
  text-decoration: none;
}

#file {
  width: 150px;
  height: 150px;
  fill: rgb(255, 153, 0);
}

#check {
  width: 150px;
  height: 150px;
  fill: #548235;
}

#inscricao > form > h2 {
  color: rgb(255, 153, 0);
  margin: 30px 0px;
  text-align: center;
}

#inscricao > form > h2 ~ p {
  font-weight: 300;
  font-style: italic;
}

#inscricao > form .inputs {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  margin-top: 10px;;
  flex-grow: 1;
}

#inscricao > form .inputs > div {
  max-width: 600px;
  width: 100%;
}

#inscricao > form .inputs .label {
  width: 150px;
}

#inscricao > form .inputs input[type="text"],
#inscricao > form .inputs select {
  background-color: #fff;
  height: 30px;
  flex-grow: 1;
  border: 1px solid #ccc;
  padding: 0px 10px;
  border-radius: 4px;
}

#inscricao > form .inputs .radioGroup input[type="radio"] {
  background-color: #fff;
  height: 20px;
  width: 20px;
}

#inscricao > form .inputs select {
  width: 120px;
}


#inscricao > form .inputs button[type="submit"] {
  background-color: #548235;
  width: 140px;
  height: 30px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.1);
  margin-top: 10px;
}

.sponsors {
  max-width: 1280px;
  margin: 0 auto;
}

.sponsors > h2 {
  color: rgb(255, 153, 0);
  margin: 30px 0px;
}
  
.sponsors .sponsor-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.sponsors .sponsor-list .sponsor-item {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.sponsors .sponsor-list .sponsor-item > a {
  width: 250px;
  height: 250px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sponsors .sponsor-list .sponsor-item > a > span {
  background-color: rgba(0,0,0,0.35);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  padding: 0px 10px;
}

.sponsors .sponsor-list .sponsor-item > a:hover > span {
  opacity: 1;
  visibility: visible;
}

.sponsors .sponsor-list .sponsor-item > a > span > i {
  display: block;
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 2a8.19 8.19 0 0 1 1.79.21 2.61 2.61 0 0 1-.78 1c-.22.17-.46.31-.7.46a4.56 4.56 0 0 0-1.85 1.67 6.49 6.49 0 0 0-.62 3.3c0 1.36 0 2.16-.95 2.87-1.37 1.07-3.46.47-4.76-.07A8.33 8.33 0 0 1 4 12a8 8 0 0 1 8-8zM5 15.8a8.42 8.42 0 0 0 2 .27 5 5 0 0 0 3.14-1c1.71-1.34 1.71-3.06 1.71-4.44a4.76 4.76 0 0 1 .37-2.34 2.86 2.86 0 0 1 1.12-.91 9.75 9.75 0 0 0 .92-.61 4.55 4.55 0 0 0 1.4-1.87A8 8 0 0 1 19 8.12c-1.43.2-3.46.67-3.86 2.53A7 7 0 0 0 15 12a2.93 2.93 0 0 1-.29 1.47l-.1.17c-.65 1.08-1.38 2.31-.39 4 .12.21.25.41.38.61a2.29 2.29 0 0 1 .52 1.08A7.89 7.89 0 0 1 12 20a8 8 0 0 1-7-4.2zm11.93 2.52a6.79 6.79 0 0 0-.63-1.14c-.11-.16-.22-.32-.32-.49-.39-.68-.25-1 .38-2l.1-.17a4.77 4.77 0 0 0 .54-2.43 5.42 5.42 0 0 1 .09-1c.16-.73 1.71-.93 2.67-1a7.94 7.94 0 0 1-2.86 8.28z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.sponsors .sponsor-list .sponsor-item > a > img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  object-position: center;
}

.select {
  position: relative;
  flex-grow: 1;
}

.clock {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='clock'%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'/%3E%3Cpath d='M16 11h-3V8a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2z'/%3E%3C/g%3E%3C/svg%3E");
}

.afterB:after {
  content: '';
  padding: 0px 10px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.bt-in {
  background-color: #548235;
  padding: 10px 20px;
  display: table;
  margin: 15px auto 0px;
  box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.1);
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  color: #FFF;
  border: none;
}

.dinner {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m466 434c0-7.74 6.26-14 14-14 7.73 0 14 6.26 14 14zm15-16v-2h2c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-6c-.56 0-1 .44-1 1 0 .55.44 1 1 1h2s-.01 2.08 0 2c-8.37.51-15 7.5-15 16v1c0 .55.44 1 1 1h30c.55 0 1-.45 1-1v-1c0-8.5-6.64-15.49-15-16zm14 20h-30c-.56 0-1 .44-1 1 0 .55.44 1 1 1h30c.55 0 1-.45 1-1 0-.56-.45-1-1-1z' fill-rule='evenodd' transform='translate(-464 -414)'/%3E%3C/svg%3E");
}

.coffee {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m13 2.25a0.75 0.75 0 0 0-0.75 0.75v0.5c0 0.699-0.551 1.25-1.25 1.25-2.06 0-3.75 1.69-3.75 3.75v0.5a0.75 0.75 0 0 0 0.75 0.75 0.75 0.75 0 0 0 0.75-0.75v-0.5c0-1.25 0.998-2.25 2.25-2.25 1.51 0 2.75-1.24 2.75-2.75v-0.5a0.75 0.75 0 0 0-0.75-0.75zm4 0.5a0.75 0.75 0 0 0-0.75 0.75v0.643c0 1.17-0.935 2.11-2.11 2.11-0.889 0-1.65 0.332-2.15 0.857-0.501 0.526-0.742 1.21-0.742 1.89a0.75 0.75 0 0 0 0.75 0.75 0.75 0.75 0 0 0 0.75-0.75c0-0.321 0.114-0.633 0.328-0.857s0.525-0.393 1.06-0.393c1.98 0 3.61-1.62 3.61-3.61v-0.643a0.75 0.75 0 0 0-0.75-0.75zm-13.4 7.5c-0.737 0-1.35 0.613-1.35 1.35v3.4c0 3.72 3.03 6.75 6.75 6.75h2c3.11 0 5.74-2.13 6.52-5h0.982c1.79 0 3.25-1.46 3.25-3.25s-1.46-3.25-3.25-3.25h-2.1-0.4-12.4zm0.15 1.5h12.2 0.25v3.25c0 2.91-2.34 5.25-5.25 5.25h-2c-2.91 0-5.25-2.34-5.25-5.25v-3.25zm14 0h0.75c0.975 0 1.75 0.775 1.75 1.75s-0.775 1.75-1.75 1.75h-0.758c0.00307-0.0833 0.00781-0.166 0.00781-0.25v-3.25z' /%3E%3C/svg%3E");
}

footer {
  max-width: 1280px;
  margin: 30px auto 0px;
}

footer > p {
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0px;
}


@media screen and (max-width: 1096px) {
  body {
    background-size: auto 100vh;
  }

  .inicio > div {
    flex-direction: column-reverse;
    align-items: center;
  }

  .inicio .date {
    width: 100%;
    max-width: none;
  }

  .inicio .date > div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .programa > div:first-of-type > div {
    flex-direction: column;
  }

  .programa > div:first-of-type > div > div {
    flex-direction: row-reverse;
  }
  .programa > div:first-of-type > div svg {
    width: 80px;
    height: 80px;
  }

  .programa .p-list {
    flex-direction: column;
    gap: 0;
  }

  .programa .p-list > .col {
    width: 100%;
  }

  
  .programa .p-list .col > .h1,
  .programa .p-list .col > .h2,
  .programa .p-list .col > .h2_1,
  .programa .p-list .col > .h2_2,
  .programa .p-list .col > .h3,
  .programa .p-list .col > .h3_1,
  .programa .p-list .col > .h3_2 {
    min-height: unset;
  }

  .programa .p-list .col > div:last-of-type {
    border-bottom: 1px dashed rgb(190,220,170);
  }

  .programa .p-list .col:last-of-type > div:last-of-type {
    border-bottom: none;
  }

  #inscricao .posters svg {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  body {
    font-size: 14px;
  }

  .inicio .date > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .date h2, .date h3 {
    font-size: 125%;
  }

  .programa table td {
    display: block;
    width: 100% !important;
  }

  .programa table td p {
    text-align: center !important;
  }

  .programa .tOrg {
    margin-top: 20px;
  }

  .programa .tOrg tr > td:first-of-type > p {
    text-align: center !important;
    margin: 0px;
  }

  .inicio > div > h1 {
    font-size: 250%;
  }

  #inscricao > form .inputs > div {
    flex-direction: column;
    align-items: flex-start !important;
  }

  #inscricao > form .inputs .label {
    width: 100%;
  }

  #inscricao > form .inputs input[type="text"] {
    width: 100%;
  }

  #inscricao > form .inputs > div:last-of-type {
    gap: 10px;
  }

  #inscricao > form .inputs > div:last-of-type > div:first-of-type {
    flex-direction: column;
    width: 100%;
  }

  #inscricao > form .inputs > div:last-of-type > div > input {
    width: 100% !important;
  }

  .sponsors .sponsor-list .sponsor-item {
    width: 180px;
  }
  
  .sponsors .sponsor-list .sponsor-item > a {
    width: 150px;
    height: 150px;
  }
  
  .sponsors .sponsor-list .sponsor-item > a > span {
    font-size: 12px;
    padding: 0px 8px;
  }
  
  .sponsors .sponsor-list .sponsor-item > a > span > i {
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
  }
  
  .sponsors .sponsor-list .sponsor-item > a > img {
    width: 120px;
    height: 120px;
  }
}

#formResponse {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: rgba(0,0,0,0.3);
  transition-delay: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

#formResponse > div {
  max-width: 600px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.1);
  overflow: hidden;
}

#formResponse > div > h3 {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 10px 0px;
  color: #fff;
}

#formResponse > div > p {
  padding: 0px 20px;
}

#formResponse > div > input {
  font-size: 100%;
  text-transform: capitalize;
  margin: 0px auto 10px;
}

#formResponse > .success > h3 {
  background-color: #548235;
}

#formResponse > .success > input {
  background-color: #548235;
}

#formResponse > .fail > h3 {
  background-color: rgb(255, 153, 0);
}

#formResponse > .fail > input {
  background-color: rgb(255, 153, 0);
}